/**
 *   priceTableIcon
 */

module.exports = function () {

    return function (app) {
        priceTableIcon()
    }
  
    function priceTableIcon() {

        jQuery('.pakete_price .mpc-pricing__property').each(function() {
            currentVal = jQuery(this).text();
            if (currentVal == 'ja') {
                jQuery(this).html('<i class="fa fa-check green_icon"></i>');
            } else 	if (currentVal == 'nein') {
                jQuery(this).html('<i class="fa fa-close red_icon"></i>');
            }
            jQuery(this).show();
        });
    }
}
  