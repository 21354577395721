/**
 *   Toggle Class
 */

module.exports = function () {

    return function (app) {
        toggleClass()
    }
  
    function toggleClass() {

        var el = document.getElementById('trigger');
        el.addEventListener("click", function () {
          console.log('clicked')
          var nav = this.parentElement.parentElement,
            _class = "is-active",
            _content = "open";
    
          el.classList.contains(_class)
            ? el.classList.remove(_class)
            : el.classList.add(_class);
          
            nav.classList.contains(_content)
            ? nav.classList.remove(_content)
            : nav.classList.add(_content);
        });
    }
}
  