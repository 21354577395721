'use strict'

/**
 * Module dependencies.
 */

// Vendor
var jQuery = window.$ = window.jQuery// = require('jquery')

var toggleClass = require('./lib/toggle-class')
var cf7LeadsForm = require('./lib/cf7-leads-forms')
var priceTableIcon = require('./lib/price-table-icon')


/**
 * Initialize a new App instannce.
 */

function App () {
  if (!(this instanceof App)) return new App()

  // init plugins

  // init actions
  $(document).ready( () => {
    this.use(toggleClass())
    this.use(cf7LeadsForm())
    this.use(priceTableIcon())
  });
}

/**
 * Use the given plugin `fn`.
 *
 * @param {Function} fn
 * @return {App} self
 * @api public
 */

App.prototype.use = function (fn) {
  fn(this)
  return this
}

/**
 * Use easing speed and type accross the application.
 *
 * @return {Object}
 * @api public
 */
// App.prototype.anim = function () {
//   return {
//     speed: 150,
//     easing: easings.easeOutQuint
//   }
// }

// run the app
App()
