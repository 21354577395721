

/**
 *   CF7 Leads Form
 */

module.exports = function () {

    return function (app) {
        cf7LeadsForm()
    }
  
    function cf7LeadsForm() {
        jQuery('.hidden_form').hide();	
        jQuery('ul.leads li').click(function(){
            var lead = jQuery(this).text();
            jQuery('.leadinput').val(lead);
            jQuery('.hidden_form h2').text(lead);	
            
            
            jQuery('ul.leads').toggle('slide', {direction: 'left'}, 'slow');
               jQuery('.hidden_form').toggle('slide', {direction: 'right'}, 'slow');
        
        });
        
            jQuery('.back_to_leads').click(function(){
            jQuery('ul.leads').toggle('slide', {direction: 'left'}, 'slow');
               jQuery('.hidden_form').toggle('slide', {direction: 'right'}, 'slow');
        
        });
    }
}
  